import * as React from "react"
import { useState } from "react"
import { ContactUsIcon } from "../components/svg"
import { Seo, Layout, Section, Grid, Container, Col } from "../components"

// import emailjs from "emailjs-com"
import _keys from "lodash/keys"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function BecomeBrokerAgent() {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [isCurrentCustomer, setIsCurrentCustomer] = useState("")
  const [currentAnnualRevenue, setCurrentAnnualRevenue] = useState("")
  const [currentAnnualGrossProfit, setCurrentAnnualGrossProfit] = useState("")
  const [yearsFreightSalesExperience, setYearsFreightSalesExperience] =
    useState("")
  const [isBusinessLocationUnitedStates, setIsBusinessLocationUnitedStates] =
    useState("")
  const [hasExistingCustomerBase, setHasExistingCustomerBase] = useState("")
  const [heardAboutUs, setHeardAboutUs] = useState("")
  const [message, setMessage] = useState("")
  const [tracking, setTracking] = useState("")
  const [showError, setShowError] = useState(false)
  const notify = () => toast("Your inquiry has been submitted successfully!")
  function clearFields() {
    setFirstName("")
    setLastName("")
    setEmail("")
    setPhoneNumber("")
    setIsCurrentCustomer("")
    setCurrentAnnualRevenue("")
    setCurrentAnnualGrossProfit("")
    setYearsFreightSalesExperience("")
    setIsBusinessLocationUnitedStates("")
    setHasExistingCustomerBase("")
    setHeardAboutUs("")
    setMessage("")
    setTracking("")
  }

  function handleSubmit(event) {
    event.preventDefault()
    if (firstName === "") {
      setShowError(true)
      return false
    }
    if (lastName === "") {
      setShowError(true)
      return false
    }
    if (email === "") {
      setShowError(true)
      return false
    }
    if (!(email.indexOf("@") > 0)) {
      setShowError(true)
      return false
    }
    if (
      email.charAt(email.length - 4) !== "." &&
      email.charAt(email.length - 3) !== "."
    ) {
      setShowError(true)
      return false
    }
    if (phoneNumber === "") {
      setShowError(true)
      return false
    }
    if (isCurrentCustomer === "") {
      setShowError(true)
      return false
    }
    if (currentAnnualRevenue === "") {
      setShowError(true)
      return false
    }
    if (currentAnnualGrossProfit === "") {
      setShowError(true)
      return false
    }
    if (yearsFreightSalesExperience === "") {
      setShowError(true)
      return false
    }
    if (isBusinessLocationUnitedStates === "") {
      setShowError(true)
      return false
    }
    if (hasExistingCustomerBase === "") {
      setShowError(true)
      return false
    }
    if (heardAboutUs === "") {
      setShowError(true)
      return false
    }
    if (message === "") {
      setShowError(true)
      return false
    }
    if (isCurrentCustomerFields === "") {
      setShowError(true)
      return false
    }

    console.log("value", {
      firstName,
      lastName,
      email,
      phoneNumber,
      isCurrentCustomer,
      currentAnnualRevenue,
      currentAnnualGrossProfit,
      yearsFreightSalesExperience,
      isBusinessLocationUnitedStates,
      hasExistingCustomerBase,
      heardAboutUs,
      message,
      tracking,
      isCurrentCustomerFields,
    })
    let myData = {
      firstName,
      lastName,
      email,
      phoneNumber,
      isCurrentCustomer,
      currentAnnualRevenue,
      currentAnnualGrossProfit,
      yearsFreightSalesExperience,
      isBusinessLocationUnitedStates,
      hasExistingCustomerBase,
      heardAboutUs,
      message,
    }

    fetch("https://api.shipx.com/contacts/registerAgent", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": "TNo7IHqnWLaMV3jyqCgSdarwt9Rib2R69DPsLER6",
      },
      body: JSON.stringify(myData),
    }).then(result => {
      // console.warn(result);
      result.json().then(resp => {
        console.warn("resp", resp)
      })
    })

    clearFields()
    notify()
  }

  const isCurrentCustomerFields = {
    yes: "Yes",
    no: "No",
  }

  const yearsFreightSalesExperienceFields = {
    zero: "0",
    one: "1",
    two: "2",
    three: "3",
    four: "4",
    five: "5",
    six: "6",
    seven: "7",
    eight: "8",
    nine: "9",
    ten: "10+",
  }

  const isBusinessLocationUnitedStatesFields = {
    yes: "Yes",
    no: "No",
  }

  const hasExistingCustomerBaseFields = {
    yes: "Yes",
    no: "No",
  }

  const heardAboutUsFields = {
    referral: "Referral",
    linkedIn: "LinkedIn",
    other: "Other",
  }

  const isCurrentCustomerFields_LIST = _keys(isCurrentCustomerFields)

  const yearsFreightSalesExperienceFields_LIST = _keys(
    yearsFreightSalesExperienceFields
  )

  const isBusinessLocationUnitedStatesFields_LIST = _keys(
    isBusinessLocationUnitedStatesFields
  )

  const hasExistingCustomerBaseFields_LIST = _keys(
    hasExistingCustomerBaseFields
  )

  const heardAboutUsFields_LIST = _keys(heardAboutUsFields)

  // const showjobtitle = () => {
  //   return (
  //     <>
  //       <input
  //         className="border-2 pl-4 rounded-3xl placeholder-black h-12 w-full"
  //         type="text"
  //         name="job-title"
  //         placeholder="Job Title"
  //         value={title}
  //         onChange={e => {
  //           setTitle(e.target.value)
  //         }}
  //         required
  //       />
  //       {showError && (
  //         <div className="text-xs pl-4 text-red-500">Can't be left Empty</div>
  //       )}
  //     </>
  //   )
  // }

  // const showtrackingtitle = () => {
  //   return (
  //     <>
  //       <input
  //         className="border-2 pl-4 rounded-3xl placeholder-black h-12 w-full"
  //         type="text"
  //         name="tracking"
  //         placeholder="Tracking Number"
  //         value={tracking}
  //         onChange={e => {
  //           setTracking(e.target.value)
  //         }}
  //         required
  //       />
  //       {showError && (
  //         <div className="text-xs pl-4 text-red-500">Can't be left Empty</div>
  //       )}
  //     </>
  //   )
  // }

  // const renderField = () => {
  //   switch (contactReason) {
  //     case isCurrentCustomerFields.show_job:
  //       return showjobtitle()
  //     case isCurrentCustomerFields.show_track:
  //       return showtrackingtitle()
  //     case isCurrentCustomerFields.show_media:
  //       return showjobtitle()
  //     case isCurrentCustomerFields.other:
  //       return showjobtitle()
  //     default:
  //       return showjobtitle()
  //   }
  // }

  return (
    <Layout>
      <Seo
        title="Let's Solve Your Final Mile Delivery Headaches Today"
        description="ShipX is at your service! Looking for your package or a final mile shipping solution? We're here for you! Contact us today to get started."
        slug="become-broker-agent"
      />
      <Section padding="none">
        <Container classes="max-w-2xl bg-white mx-auto lg:mt-40 sm:mt-20 py-8 border-2 mb-8">
          <div>
            <Grid classes="sm:grid-cols-12 justify-center mb-6 gap-10">
              <Col classes="order-1 col-span-1 sm:col-span-3 mx-auto">
                <ContactUsIcon className="w-32 h-32" />
              </Col>
              <Col classes="order-2 col-span-1 sm:col-span-9 mx-auto">
                <h1 className="font-bold mb-2">Become a Broker Agent</h1>
                <p className="">
                  Schedule a meeting to learn more about the ShipX broker agent
                  program.
                </p>
              </Col>
            </Grid>
            <div className="">
              <form className="sm:pt-5" onSubmit={handleSubmit}>
                <Grid classes="lg:grid-cols-2 gap-x-4 gap-y-8">
                  <Col classes="lg:col-span-1">
                    <div className="pb-3 font-bold">
                      <p>First Name</p>
                    </div>
                    <input
                      className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                      type="text"
                      name="firstName"
                      value={firstName}
                      onChange={e => {
                        setFirstName(e.target.value)
                      }}
                      placeholder="John"
                      required
                    />
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please enter your first name.
                      </div>
                    )}
                  </Col>
                  <Col classes="lg:col-span-1">
                    <div className="pb-3 font-bold">
                      <p>Last name</p>
                    </div>
                    <input
                      className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                      type="text"
                      name="lastName"
                      value={lastName}
                      onChange={e => {
                        setLastName(e.target.value)
                      }}
                      placeholder="Doe"
                      required
                    />
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please enter your last name.
                      </div>
                    )}
                  </Col>
                  <Col classes="lg:col-span-1">
                    <div className="pb-3 font-bold">
                      <p>Phone Number</p>
                    </div>
                    <input
                      className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                      type="text"
                      name="phone-number"
                      placeholder="(123) 123-1234"
                      value={phoneNumber}
                      onChange={e => {
                        setPhoneNumber(e.target.value)
                      }}
                      required
                    />
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please enter your phone number.
                      </div>
                    )}
                  </Col>
                  <Col classes="lg:col-span-1">
                    <div className="pb-3 font-bold">
                      <p>Email Address</p>
                    </div>
                    <input
                      className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                      type="email"
                      name="email"
                      placeholder="name@domain.com"
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value)
                      }}
                      required
                    />
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please enter a valid email address.
                      </div>
                    )}
                  </Col>
                  <Col classes="lg:col-span-2">
                    <div className="pb-3 font-bold">
                      <p>Are you a current ShipX customer?</p>
                    </div>
                    <select
                      className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                      value={isCurrentCustomer}
                      name="isCurrentCustomer"
                      onChange={e =>
                        setIsCurrentCustomer(
                          e.target.value === "Select option..."
                            ? undefined
                            : e.target.value
                        )
                      }
                    >
                      <option value={undefined} className="form-control">
                        Select option...
                      </option>
                      {isCurrentCustomerFields_LIST.map(connector => {
                        return (
                          <option
                            key={connector}
                            value={isCurrentCustomerFields[connector]}
                            className="form-control"
                          >
                            {isCurrentCustomerFields[connector]}
                          </option>
                        )
                      })}
                    </select>
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please select an option.
                      </div>
                    )}
                  </Col>
                  <Col classes="lg:col-span-2">
                    <div className="pb-3 font-bold">
                      <p>What is your current annual revenue?</p>
                    </div>
                    <input
                      className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                      type="text"
                      name="currentAnnualRevenue"
                      placeholder="Current annual revenue"
                      value={currentAnnualRevenue}
                      onChange={e => {
                        setCurrentAnnualRevenue(e.target.value)
                      }}
                      required
                    />
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please enter your current annual revenue.
                      </div>
                    )}
                  </Col>
                  <Col classes="lg:col-span-2">
                    <div className="pb-3 font-bold">
                      <p>What is your current annual gross profit?</p>
                    </div>
                    <input
                      className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                      type="text"
                      name="currentAnnualGrossProfit"
                      placeholder="Current annual gross profit"
                      value={currentAnnualGrossProfit}
                      onChange={e => {
                        setCurrentAnnualGrossProfit(e.target.value)
                      }}
                      required
                    />
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please enter your current annual gross profit.
                      </div>
                    )}
                  </Col>
                  <Col classes="lg:col-span-2">
                    <div className="pb-3 font-bold">
                      <p>
                        How many years of experience do you have with freight
                        sales?
                      </p>
                    </div>
                    <select
                      className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                      value={yearsFreightSalesExperience}
                      name="yearsFreightSalesExperience"
                      onChange={e =>
                        setYearsFreightSalesExperience(
                          e.target.value === "Select option..."
                            ? undefined
                            : e.target.value
                        )
                      }
                    >
                      <option value={undefined} className="form-control">
                        Select option...
                      </option>
                      {yearsFreightSalesExperienceFields_LIST.map(connector => {
                        return (
                          <option
                            key={connector}
                            value={yearsFreightSalesExperienceFields[connector]}
                            className="form-control"
                          >
                            {yearsFreightSalesExperienceFields[connector]}
                          </option>
                        )
                      })}
                    </select>
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please select an option.
                      </div>
                    )}
                  </Col>
                  <Col classes="lg:col-span-2">
                    <div className="pb-3 font-bold">
                      <p>Is your business located in the United States?</p>
                    </div>
                    <select
                      className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                      value={isBusinessLocationUnitedStates}
                      name="isBusinessLocationUnitedStates"
                      onChange={e =>
                        setIsBusinessLocationUnitedStates(
                          e.target.value === "Select option..."
                            ? undefined
                            : e.target.value
                        )
                      }
                    >
                      <option value={undefined} className="form-control">
                        Select option...
                      </option>
                      {isBusinessLocationUnitedStatesFields_LIST.map(
                        connector => {
                          return (
                            <option
                              key={connector}
                              value={
                                isBusinessLocationUnitedStatesFields[connector]
                              }
                              className="form-control"
                            >
                              {isBusinessLocationUnitedStatesFields[connector]}
                            </option>
                          )
                        }
                      )}
                    </select>
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please select an option.
                      </div>
                    )}
                  </Col>
                  <Col classes="lg:col-span-2">
                    <div className="pb-3 font-bold">
                      <p>Do you have an existing customer base?</p>
                    </div>
                    <select
                      className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                      value={hasExistingCustomerBase}
                      name="hasExistingCustomerBase"
                      onChange={e =>
                        setHasExistingCustomerBase(
                          e.target.value === "Select option..."
                            ? undefined
                            : e.target.value
                        )
                      }
                    >
                      <option value={undefined} className="form-control">
                        Select option...
                      </option>
                      {hasExistingCustomerBaseFields_LIST.map(connector => {
                        return (
                          <option
                            key={connector}
                            value={hasExistingCustomerBaseFields[connector]}
                            className="form-control"
                          >
                            {hasExistingCustomerBaseFields[connector]}
                          </option>
                        )
                      })}
                    </select>
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please select an option.
                      </div>
                    )}
                  </Col>
                  <Col classes="lg:col-span-2">
                    <div className="pb-3 font-bold">
                      <p>How did you hear about us?</p>
                    </div>
                    <select
                      className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                      value={heardAboutUs}
                      name="heardAboutUs"
                      onChange={e =>
                        setHeardAboutUs(
                          e.target.value === "Select option..."
                            ? undefined
                            : e.target.value
                        )
                      }
                    >
                      <option value={undefined} className="form-control">
                        Select option...
                      </option>
                      {heardAboutUsFields_LIST.map(connector => {
                        return (
                          <option
                            key={connector}
                            value={heardAboutUsFields[connector]}
                            className="form-control"
                          >
                            {heardAboutUsFields[connector]}
                          </option>
                        )
                      })}
                    </select>
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please select an option.
                      </div>
                    )}
                  </Col>
                  <Col classes="lg:col-span-2">
                    <div className="pb-3 font-bold">
                      <p>Do you have additional questions or information?</p>
                    </div>
                    <textarea
                      className="border-2 rounded-3xl placeholder-black w-full p-3"
                      rows="8"
                      name="message"
                      placeholder="Please leave a detailed message..."
                      value={message}
                      onChange={e => {
                        setMessage(e.target.value)
                      }}
                      required
                    ></textarea>
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Can't be left blank.
                      </div>
                    )}
                  </Col>
                </Grid>
                <Container padding="none" classes="py-6">
                  <center>
                    <button className="px-10 py-6 text-white font-bold lg:text-xl text-lg  rounded-full bg-accent-light hover:bg-accent hover:text-white transition duration-150 ease-in-out">
                      Submit Inquiry
                    </button>
                    <ToastContainer
                      position="top-right"
                      autoClose={5000}
                      hideProgressBar={false}
                      // newestOnTop={false}
                      cloSeonClick
                      rtl={false}
                      pauSeonFocusLoss
                      draggable
                      pauSeonHover
                    />
                  </center>
                </Container>
              </form>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default BecomeBrokerAgent
